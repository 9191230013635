// Widths and spacing

// Breakpoints
$breakpoints: (
  small: 480px,
  medium: 640px,
  large: 820px,
);

// Containers widths
$container--width-boxed: 1440px; // boxed container width
$container--width: 1080px; // container width
$container--width-sm: 896px; // container sm width
$container--width-xs: 620px; // container xs width
$container--padding__mobile: 16px; // container, left and right padding (mobile)
$container--padding__desktop: 24px; // container, left and right padding (desktop)

// Global spacing
$spacing--shared-v: 24px; // used on many different elements (e.g. hr, ul, ol and more)
$spacing--shared-h: 24px;
$spacing--h1-t: 48px;
$spacing--h1-b: 32px;
$spacing--h2-t: 48px;
$spacing--h2-b: 32px;
$spacing--h3-t: 36px;
$spacing--h3-b: 24px;
$spacing--hx-t: 24px;
$spacing--hx-b: 24px;
$spacing--figure-v: 48px;
$spacing--figure-h: 0;
$spacing--figcaption-v: 8px;
$spacing--figcaption-h: 0;
$spacing--table-cell-v: 8px;
$spacing--table-cell-h: 16px;
$spacing--code-v: 2px;
$spacing--code-h: 4px;

// Sections spacing
$section--padding__mobile: 48px; // section, top and bottom padding (mobile)
$section--padding__desktop: 80px; // section, top and bottom padding (desktop)
$section-header--padding__mobile: 48px; // section header, bottom padding (mobile)
$section-header--padding__desktop: 80px; // section header, bottom padding (desktop)
