.section-inner {
  position: relative; // To always display inner elements above pseudo decorative stuff
  padding-top: $section--padding__mobile;
  padding-bottom: $section--padding__mobile;
}

.section-header {
  padding-bottom: $section-header--padding__mobile;
}

@include media(">medium") {
  .section-inner {
    padding-top: $section--padding__desktop;
    padding-bottom: $section--padding__desktop;
  }

  .section-header {
    padding-bottom: $section-header--padding__desktop;
  }
}
